import React from "react";
import Container from "../components/container";
import Layout from "../components/layouts/main-layout";
import TeamMember from "../components/teammember";
import Seo from "../components/seo";
import Button from "../components/button";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Team = () => {
  const data = useStaticQuery(graphql`
    query TeamQuery {
      hendrik: file(
        relativePath: { eq: "team/tidens-surfhuus_team-hendrik.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 85
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      sven: file(relativePath: { eq: "team/tidens-surfhuus_team-sven.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 85
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      katha: file(relativePath: { eq: "team/tidens-surfhuus_team-katha.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 85
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      kira: file(relativePath: { eq: "team/tidens-surfhuus_team-kira.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 85
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      theresa: file(
        relativePath: { eq: "team/tidens-surfhuus_team-theresa.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 85
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      maxime: file(
        relativePath: { eq: "team/tidens-surfhuus_team-maxime.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 85
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      anouk: file(relativePath: { eq: "team/tidens-surfhuus_team-anouk.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 85
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      jonas: file(relativePath: { eq: "team/tidens-surfhuus_team-jonas.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 85
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      emilie: file(
        relativePath: { eq: "team/tidens-surfhuus_team-emilie.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 85
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      linus: file(
        relativePath: { eq: "team/tidens-surfhuus_team-linus.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 85
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      elara: file(
        relativePath: { eq: "team/tidens-surfhuus_team-elara.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 85
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      sophia: file(
        relativePath: { eq: "team/tidens-surfhuus_team-sophia.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 85
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      lotta: file(
        relativePath: { eq: "team/tidens-surfhuus_team-lotta.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 85
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      merle: file(
        relativePath: { eq: "team/tidens-surfhuus_team-merle.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 85
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      hermann: file(
        relativePath: { eq: "team/tidens-surfhuus_team-hermann.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 85
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      carlo: file(
        relativePath: { eq: "team/tidens-surfhuus_team-carlo.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 85
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      cinthia: file(
        relativePath: { eq: "team/tidens-surfhuus_team-cinthia.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 85
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      ben: file(
        relativePath: { eq: "team/tidens-surfhuus_team-ben.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 85
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      placeholderMale: file(
        relativePath: { eq: "team/tidens-surfhuus_team-placeholder-male.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 85
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      kara: file(
        relativePath: { eq: "team/tidens-surfhuus_team-kara-dog.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 85
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      leiti: file(
        relativePath: { eq: "team/tidens-surfhuus_team-leiti.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            layout: CONSTRAINED
            quality: 85
            transformOptions: { fit: CONTAIN }
          )
        }
      }
    }
  `);

  const imageHendrik = (
    <GatsbyImage
      image={getImage(data.hendrik)}
      key="hendrik"
      id="hendrik"
      alt="Windsurf, Wellenreit und Wingsurflehrer Hendrik"
      className="rounded-full grayscale hover:grayscale-0 cursor-pointer"
    />
  );

  const imageSven = (
    <GatsbyImage
      key="sven"
      id="sven"
      image={getImage(data.sven)}
      alt="Kitesurf und SUP Lehrer Sven"
      className="rounded-full grayscale hover:grayscale-0 cursor-pointer"
    />
  );

  const imageKira = (
    <GatsbyImage
      image={getImage(data.kira)}
      alt="Wellenreit und Kitesurflehrerin Kira"
      className="rounded-full grayscale hover:grayscale-0 cursor-pointer"
    />
  );

  const imageKatha = (
    <GatsbyImage
      image={getImage(data.katha)}
      alt="Kitesurf und Wingsurf Lehrerin Katha"
      className="rounded-full grayscale hover:grayscale-0 cursor-pointer"
    />
  );

  const imageTheresa = (
    <GatsbyImage
      image={getImage(data.theresa)}
      alt="Kitesurf und Welleneit Lehrerin Theresa"
      className="rounded-full grayscale hover:grayscale-0 cursor-pointer"
    />
  );

  const imageMaxime = (
    <GatsbyImage
      image={getImage(data.maxime)}
      alt="Kitesurflehrer Maxime"
      className="rounded-full grayscale hover:grayscale-0 cursor-pointer"
    />
  );

  const imageLinus = (
    <GatsbyImage
      image={getImage(data.linus)}
      alt="Wassersportasssistent Linus"
      className="rounded-full grayscale hover:grayscale-0 cursor-pointer"
    />
  );

  const imageAnouk = (
    <GatsbyImage
      image={getImage(data.anouk)}
      alt="Wassersportassistentin Anouk"
      className="rounded-full grayscale hover:grayscale-0 cursor-pointer"
    />
  );

  const imageJonas = (
    <GatsbyImage
      image={getImage(data.jonas)}
      alt="Wassersportassistent Jonas"
      className="rounded-full grayscale hover:grayscale-0 cursor-pointer"
    />
  );

  const imageEmilie = (
    <GatsbyImage
      image={getImage(data.emilie)}
      alt="Kite- und Windsurflehrerin Emilie"
      className="rounded-full grayscale hover:grayscale-0 cursor-pointer"
    />
  );

  const imageElara = (
    <GatsbyImage
      image={getImage(data.elara)}
      alt="Wellenreitlehrerin Elara"
      className="rounded-full grayscale hover:grayscale-0 cursor-pointer"
    />
  );

  const imageSophia = (
    <GatsbyImage
      image={getImage(data.sophia)}
      alt="Wellenreitlehrerin Sophia"
      className="rounded-full grayscale hover:grayscale-0 cursor-pointer"
    />
  );

  const imageLotta = (
    <GatsbyImage
      image={getImage(data.lotta)}
      alt="Wassersportassistentin Lotta"
      className="rounded-full grayscale hover:grayscale-0 cursor-pointer"
    />
  );

  const imageMerle = (
    <GatsbyImage
      image={getImage(data.merle)}
      alt="Windsurflehrerin Merle"
      className="rounded-full grayscale hover:grayscale-0 cursor-pointer"
    />
  );

  const imageHermann = (
    <GatsbyImage
      image={getImage(data.hermann)}
      alt="Wassersportassistent Hermann"
      className="rounded-full grayscale hover:grayscale-0 cursor-pointer"
    />
  );

  const imageCinthia = (
    <GatsbyImage
      image={getImage(data.cinthia)}
      alt="Windsurflehrerin Cinthia"
      className="rounded-full grayscale hover:grayscale-0 cursor-pointer"
    />
  );

  const imageBen = (
    <GatsbyImage
      image={getImage(data.ben)}
      alt="Wassersportassistent Ben"
      className="rounded-full grayscale hover:grayscale-0 cursor-pointer"
    />
  );

  const imageCarlo = (
    <GatsbyImage
      image={getImage(data.carlo)}
      alt="Wassersportassistent Carlo"
      className="rounded-full grayscale hover:grayscale-0 cursor-pointer"
    />
  );

  const imageKara = (
    <GatsbyImage
      image={getImage(data.kara)}
      alt="Stationshund Kara"
      className="rounded-full grayscale hover:grayscale-0 cursor-pointer"
    />
  );

  const imageLeiti = (
    <GatsbyImage
      image={getImage(data.leiti)}
      alt="Stationshund Leiti"
      className="rounded-full grayscale hover:grayscale-0 cursor-pointer"
    />
  );

  const testData = [
    {
      name: "hendrik",
      sport: "Windsurfen, Wellenreiten, Wingfoilen"
    },
    {
      name: "sven",
      sport: "Kitesurfen, SUP"
    },

  ]

  const teamMembers = [
    <TeamMember
      image={imageHendrik}
      membername="Hendrik"
      sport="Windsurfen, Wellenreiten, Wingfoilen"
    />,
    <TeamMember image={imageSven} membername="Sven" sport="Kitesurfen, SUP" />,
    <TeamMember
      image={imageKira}
      membername="Kira"
      sport="Wellenreiten, Kitesurfen, Wingsurfen"
    />,
    <TeamMember
      image={imageKatha}
      membername="Katha"
      sport="Kitesurfen, Wingfoilen"
    />,
    <TeamMember
      image={imageTheresa}
      membername="Theresa"
      sport="Kitesurfen, Wellenreiten"
    />,
    <TeamMember
      image={imageMaxime}
      membername="Maxime"
      sport="Kitesurfen, Wingfoilen"
    />,
    <TeamMember image={imageLinus} membername="Linus" sport="Kitesurfen" />,
    <TeamMember
      image={imageAnouk}
      membername="Anouk"
      sport="Windsurfen, Wellenreiten, Kitesurfen"
    />,
    <TeamMember
      image={imageJonas}
      membername="Jonas"
      sport="Windsurfen, Wellenreiten"
    />,
    <TeamMember
      image={imageEmilie}
      membername="Emilie"
      sport="Kitesurfen, Windsurfen"
    />,
    <TeamMember
      image={imageBen}
      membername="Ben"
      sport="Kitesurfen"
    />,
    <TeamMember
      image={imageElara}
      membername="Elara"
      sport="Wellenreiten, Windsurfen"
    />,
    <TeamMember
      image={imageSophia}
      membername="Sophia"
      sport="Wellenreiten, Windsurfen"
    />,
    <TeamMember
      image={imageLotta}
      membername="Lotta"
      sport="SUP, Kitesurfen"
    />,
    <TeamMember
      image={imageMerle}
      membername="Merle"
      sport="Windsurfen, Kitesurfen"
    />,
    <TeamMember
      image={imageHermann}
      membername="Hermann"
      sport="Kitesurfen"
    />,
    <TeamMember
      image={imageCarlo}
      membername="Carlo"
      sport="Windsurfen"
    />,
    <TeamMember
      image={imageCinthia}
      membername="Cinthia"
      sport="Windsurfen, Wellenreiten"
    />,
    <TeamMember image={imageKara} membername="Kara" sport="Stationshündin" />,
    <TeamMember image={imageLeiti} membername="Leiti" sport="Stationsleiti" />,
  ];

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    while (currentIndex > 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  const shuffledMembers = teamMembers;
  //const shuffledData = shuffle(testData);
  //console.log(shuffledData);

  return (
    <Layout>
      <Seo
        title="Team der Surfschule Tidens Surfhuus"
        description="Die LehrerInnen der Surfschule Tidens Surfhuus auf Langeoog sind ausgebildete VDWS Instructoren."
      />
      <Container>
        <h1>Team</h1>
        <p>
          Hallo! Wir sind Sven, Katha, Hendrik und Kira und haben 2022 die
          Surfschule Tidens Surfhuus auf Langeoog gegründet. Wir sind süchtig
          nach Wassersport, lieben das Leben auf unserer wunderschönen Insel und
          wollen unsere Leidenschaft und Freude am Surfen mit euch teilen.{" "}
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 my-6 mt-10">
          {shuffledMembers.map((member, index) => (
            <div key={index} id={index}>
              {member}
            </div>
          ))}
        </div>
        <div className="mb-6">
          <h3>Wir suchen dich!</h3>
          <p>
            Wenn du Lust hast, Teil unseres Teams zu werden und uns während der
            Saison eine Zeit lang zu unterstützen, schicke uns einfach eine
            Nachricht über das Kontaktformular. Wir sind immer auf der Suche
            nach jungen, motivierten Menschen, die genauso viel Freude am
            Strandleben und Surfen haben wie wir.
          </p>
          <Button
            external={false}
            to="/kontakt/"
            title="Kontaktformular"
            btntype="secondary"
          />
        </div>
      </Container>
    </Layout>
  );
};

export default Team;
